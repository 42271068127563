<div class="modal-header">
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h3 class="modal-title w-100 text-center text-default mt-4 font-weight-bold" id="myModalLabel">{{ title }}</h3>
  </div>

<!-- Info -->
<div class="container">
  <section class="my-5">
    <iframe width="100%" height="415" [src]="url | domseguro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
  </section>
</div>
<!-- Info -->






