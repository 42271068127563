<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark unique-color" [containerInside]="true">

    <!-- Navbar brand -->
    <mdb-navbar-brand><a class="navbar-brand" href="#">SEA</a></mdb-navbar-brand>
  
    <!-- Collapsible content -->
    <links>
  
      <!-- Links -->
      <ul class="navbar-nav mr-auto">


        <!-- Dropdown -->
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Estudiantes<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/estudiante/sistema']" routerLinkActive="router-link-active">¿Cómo estudiar en SEA?</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/estudiante/cursos']" routerLinkActive="router-link-active">Cursos</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/estudiante/materiales']" routerLinkActive="router-link-active">Materiales</a>
          </div>
        </li>

        <!-- Dropdown -->
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Asesores<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/asesores/perfil']" routerLinkActive="router-link-active">Perfil</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/asesores/capacitacion']" routerLinkActive="router-link-active">Curso de capacitación</a>
            <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/asesores/programacionasesores']" routerLinkActive="router-link-active">Programación regular</a>
          </div>
        </li>

        <!-- Dropdown -->
            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                  Coordinadores Estatales<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                  <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/coordinadores/sede']" routerLinkActive="router-link-active">¿Cómo puedo instalar una sede SEA?</a>
                  <a class="dropdown-item waves-light" href="https://drive.google.com/drive/folders/10QALBrWSWmqyTnICTnB2hpuKJf-W38vB?usp=sharing" target="_blank">Difunde SEA</a>
                </div>
              </li>
  
        <!-- Dropdown -->
        <li class="nav-item dropdown" dropdown>
          <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            Materiales Audiovisuales<span class="caret"></span></a>
          <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
            <a class="dropdown-item waves-light" mdbWavesEffect (click)="ruta(1)">Lengua y Comunicación</a>
            <a class="dropdown-item waves-light" mdbWavesEffect (click)="ruta(2)">Cálculo y Resolución de Problemas</a>
            <a class="dropdown-item waves-light" mdbWavesEffect (click)="ruta(3)">Salud y Ambiente</a>
            <a class="dropdown-item waves-light" mdbWavesEffect (click)="ruta(4)">Familia, Comunidad y Sociedad</a>
          </div>
        </li>


      </ul>

    </links>
    <!-- Collapsible content -->
  </mdb-navbar>
  <!--/.Navbar-->