<div class="modal-header">
  
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
    <h3 class="modal-title w-100 text-center text-default mt-4 font-weight-bold" id="myModalLabel">Conoce SEA</h3>
  </div>

<!-- Info -->
<div class="container">
  <section class="my-5">

    <!-- Grid row -->
    <div>

        <!--Linea 1-->
        <div class="row">
            <!--Grid column-->
            <div class="col-md-6 col-sm-12 col-xs-12 my-2">
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-2">
                        <img class="img-fluid" width="100%" src="assets/img/biblioteca.png">
                    </div>
                    <div class="col-9 col-sm-9 col-md-9 col-lg-10">
                      <h6 class="font-weight-bold text-default">¿Qué ofrece SEA?</h6>
                      <p class="dark-grey-text small-text"> <small> 
                        Conocimientos y habilidades que te permiten estudiar en forma independiente y 
                        concluir tu Educación Básica con el certificado correspondiente.</small></p>
                    </div>
                </div>
            </div>
            <!--Grid column-->

            <!--Grid column-->
            <div class="col-md-6 col-sm-12 my-2">
                <div class="row">
                    <div class="col-3 col-sm-3 col-md-3 col-lg-2">
                        <img class="img-fluid" width="100%" src="assets/img/cuaderno.png">
                    </div>
                    <div class="col-9 col-sm-9 col-md-9 col-lg-10">
                      <h6 class="font-weight-bold text-default">¿Cómo se estudia en SEA?</h6>
                      <p class="dark-grey-text small-text"> <small> De manera independiente, con el apoyo de guías de aprendizaje 
                        y programas audiovisuales. No es necesario asistir diariamente a un salón de clases. Puedes estudiar 
                        en casa y asistir semanalmente a una sede de asesoría, donde recibirás la atención personal de un Asesor 
                        y podrás compartir tus experiencias con otros estudiantes inscritos en el programa.</small></p>
                  </div>
                </div>
            </div>
            <!--Grid column-->
        </div>

        <!--Linea 2-->
        <div class="row">
          <!--Grid column-->
          <div class="col-md-6 col-sm-12 col-xs-12 my-2">
              <div class="row">
                  <div class="col-3  col-sm-3 col-md-3 col-lg-2">
                      <img class="img-fluid" width="100%" src="assets/img/manzana.png">
                  </div>
                  <div class="col-9 col-sm-9 col-md-9 col-lg-10">
                      <h6 class="font-weight-bold text-default">¿Qué se aprende en SEA?</h6>
                      <p class="dark-grey-text small-text"> <small> Al cursar dos niveles, inicial y/o avanzado, podrás adquirir conocimientos útiles para continuar tus estudios, relacionados con las siguientes áreas:
                          <li>Lengua y Comunicación</li>
                          <li>Cálculo y Resolución de Problemas</li>
                          <li>Salud y Ambiente</li>
                          <li>Familia, Comunidad y Sociedad</li>
                          <li>Inglés (si se requiere)</li>
                      </small></p>
                  </div>
              </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-6 col-sm-12 my-2">
              <div class="row">
                  <div class="col-3 col-sm-3 col-md-3 col-lg-2">
                      <img class="img-fluid" width="100%" src="assets/img/certificado.png">
                  </div>
                  <div class="col-9 col-sm-9 col-md-9 col-lg-10">
                    <h6 class="font-weight-bold text-default">¿Después de SEA?</h6>
                    <p class="dark-grey-text small-text"> <small> 
                      Una vez concluida tu educación secundaria, obtendrás un Certificado de Educación Básica con validez oficial 
                      por parte de la Secretaría de Educación Pública. Estarás en condiciones de saber más para mejorar tu trabajo 
                      y tu vida. Si lo deseas podrás continuar tus estudios de nivel medio superior.</small></p>
                </div>
              </div>
          </div>
          <!--Grid column-->
      </div>

    </div>
    <!-- Grid row -->


  </section>
</div>
<!-- Info -->






