<app-header></app-header>

<!--Banner-->
<section>
    <mdb-carousel class="carousel  carousel-fade" [animation]="'fade'" style="height: 350px;">
        <mdb-carousel-item>
            <img class="d-block w-100" src="assets/img/banner_sep.png">
        </mdb-carousel-item>
    </mdb-carousel>
</section>
<!--Banner-->

<!--Texto principal-->
<section>
    <div class="container text-center  my-4">
        <h3 class="text-unique mt-5 font-weight-bold"> Secundaria a Distancia para Adultos (SEA)</h3>
        <br>
        <p _ngcontent-otn-c60="" class="text-center text-justify dark-grey-text">
            Si eres mayor de 15 años y no has concluido tu Educación Básica, el Programa Secundaria a Distancia para Adultos (SEA) te permite continuar con tu preparación educativa mediante el estudio independiente de los contenidos de guías de aprendizaje y materiales
            audiovisuales.
        </p>

        <button mdbBtn color="default" rounded="true" (click)="openModal()">Conoce más de SEA </button>

    </div>
</section>
<!--Intro-->

<!--Carousel Notificaciones-->
<section>
    <div class="container">
        <mdb-carousel [controls]="true" [indicators]="true" [animation]="'fade'">
            <mdb-carousel-item *ngFor="let item of Carousel">
                <img *ngIf="!item.url_carrusel" src="{{item.img_carrusel}}" class="d-block w-100" alt="..." />
                <a *ngIf="item.url_carrusel" href="{{item.url_carrusel}}" target="{{item.pestana_nueva == 1 ? '_blank' : '_self'}}">
                    <img src="{{item.img_carrusel}}" class="d-block w-100" alt="..." />
                </a>

            </mdb-carousel-item>


        </mdb-carousel>
    </div>
</section>
<hr class="container">
<!--Carousel-->

<section>
    <div class="container mt-5 mb-5">
        <h3 class="text-center text-unique mt-4 font-weight-bold "> Experiencias exitosas</h3>
        <mdb-carousel [isControls]="true" class="carousel-multi-item multi-animation mt-3" [type]="'carousel-multi-item'" [animation]="'slide'">
            <mdb-carousel-item *ngFor="let item of slides; let i = index">
                <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                    <mdb-card class="my-1" id="tarjetones">
                        <a (click)="emergenteModal(card.title, card.url)" *ngIf="card.tipo == 1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                            <mdb-card-body>
                                <mdb-card-title class="text-center">
                                    <p>{{card.title}}</p>
                                </mdb-card-title>
                            </mdb-card-body>
                        </a>
                        <a *ngIf="card.tipo == 2" href="{{card.url}}" target="_blank">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                            <mdb-card-body>
                                <mdb-card-title class="text-center text-white">
                                    <p>{{card.title}}</p>
                                </mdb-card-title>
                            </mdb-card-body>
                        </a>
                    </mdb-card>
                </div>
            </mdb-carousel-item>
        </mdb-carousel>

    </div>
</section>
<hr class="container">


<!--Cards-->
<section _ngcontent-sur-c60="" class="mt-5">
    <div class="container">
        <h3 class="text-center text-unique mt-4 font-weight-bold"> SEA está dirigido a</h3>
        <div class="card-deck">
            <div class="row">

                <!-- Card -->
                <div class="col-md-4 col-xs-12 mt-2">
                    <mdb-card>
                        <div>
                            <!-- Card img -->
                            <mdb-card-img src="assets/img/estudiantes.png" alt="Card image cap"></mdb-card-img>

                        </div>
                        <!--Card content-->
                        <mdb-card-body class="text-center">
                            <!--Title-->
                            <mdb-card-title>
                                <h5 class="text-center text-unique font-weight-bold">Estudiantes</h5>
                            </mdb-card-title>

                            <!--Text-->
                            <button mdbBtn type="button" color="default" rounded="true" mdbWavesEffect [routerLink]="['/estudiante/sistema']" routerLinkActive="router-link-active">¿Cómo estudiar en SEA?</button>
                            <br>
                            <button mdbBtn type="button" color="default-2" rounded="true" mdbWavesEffect [routerLink]="['/estudiante/cursos']" routerLinkActive="router-link-active">Cursos</button>
                            <br>
                            <button mdbBtn type="button" color="default-3" rounded="true" mdbWavesEffect [routerLink]="['/estudiante/materiales']" routerLinkActive="router-link-active">Materiales</button>
                        </mdb-card-body>
                    </mdb-card>
                </div>

                <!-- Card -->
                <div class="col-md-4 col-xs-12 mt-2">
                    <mdb-card>
                        <div>
                            <!-- Card img -->
                            <mdb-card-img src="assets/img/asesores.png" alt="Card image cap"></mdb-card-img>

                        </div>
                        <!--Card content-->
                        <mdb-card-body class="text-center">
                            <!--Title-->
                            <mdb-card-title>
                                <h5 class="text-center text-unique font-weight-bold">Asesores <br> académicos</h5>
                            </mdb-card-title>
                            <br>

                            <!--Text-->
                            <button mdbBtn type="button" color="default-2" rounded="true" mdbWavesEffect [routerLink]="['/asesores/perfil']" routerLinkActive="router-link-active">Perfil y funciones</button>
                            <br>
                            <button mdbBtn type="button" color="default" rounded="true" mdbWavesEffect [routerLink]="['/asesores/capacitacion']" routerLinkActive="router-link-active">Curso de capacitación</button>
                            <br>
                            <button mdbBtn type="button" color="default-3" rounded="true" mdbWavesEffect [routerLink]="['/asesores/programacionasesores']" routerLinkActive="router-link-active">Programación regular</button>
                        </mdb-card-body>
                    </mdb-card>
                </div>

                <!-- Card -->
                <div class="col-md-4 col-xs-12 mt-2">
                    <mdb-card>
                        <div>
                            <!-- Card img -->
                            <mdb-card-img src="assets/img/estatales.png" alt="Card image cap"></mdb-card-img>

                        </div>
                        <!--Card content-->
                        <mdb-card-body class="text-center">
                            <!--Title-->
                            <mdb-card-title>
                                <h5 class="text-center text-unique font-weight-bold">Coordinadores <br> Estatales</h5>
                            </mdb-card-title>
                            <br>

                            <!--Text-->
                            <button mdbBtn type="button" color="default-3" rounded="true" mdbWavesEffect [routerLink]="['/coordinadores/sede']" routerLinkActive="router-link-active">Instalar una sede SEA</button>
                            <br>
                            <a mdbBtn type="button" color="default-2" rounded="true" href="https://drive.google.com/drive/folders/10QALBrWSWmqyTnICTnB2hpuKJf-W38vB?usp=sharing" target="_blank">Difunde SEA</a>
                        </mdb-card-body>
                    </mdb-card>
                </div>

            </div>
        </div>
    </div>
</section>
<!--Cards-->

<hr class="container">

<div class="container">

    <h3 class="text-center text-default mt-5 font-weight-bold"> Materiales Audiovisuales SEA</h3>
    <p _ngcontent-otn-c60="" class="my-4 text-center text-justify dark-grey-text">
        Complementa tu aprendizaje con los materiales audiovisuales que tenemos para ti, selecciona la asignatura de tu interés:
    </p>

    <div class="row">
        <div class="col-lg-3 col-sm-6 mt-1">
            <a [routerLink]="['/audiovisuales/lyc']" routerLinkActive="router-link-active">
                <div class="card" style="height: 120px;">
                    <div class="card-body-3">
                        <h5 class="card-title text-white font-weight-bold mt-2">
                            <span class="float-right display-5 opacity-5"><mdb-icon class="gradient-1" fas icon="book-open"></mdb-icon></span> Lengua y Comunicación </h5>
                    </div>
                </div>

            </a>
        </div>

        <div class="col-lg-3 col-sm-6 mt-1">
            <a [routerLink]="['/audiovisuales/cyrp']" routerLinkActive="router-link-active">
                <div class="card" style="height: 120px;">
                    <div class="card-body-2">
                        <h5 class="card-title text-white font-weight-bold mt-2">
                            <span class="float-right display-5 opacity-5"><mdb-icon class="gradient-1" fas icon="pen"></mdb-icon></span> Cálculo y Resolución de Problemas </h5>
                    </div>
                </div>

            </a>
        </div>

        <div class="col-lg-3 col-sm-6 mt-1">
            <a [routerLink]="['/audiovisuales/sya']" routerLinkActive="router-link-active">
                <div class="card" style="height: 120px;">
                    <div class="card-body-4">
                        <h5 class="card-title text-white font-weight-bold mt-2">
                            <span class="float-right display-5 opacity-5"><mdb-icon class="gradient-1" fas icon="briefcase-medical"></mdb-icon></span> Salud y Ambiente </h5>
                    </div>
                </div>

            </a>
        </div>

        <div class="col-lg-3 col-sm-6 mt-1">
            <a [routerLink]="['/audiovisuales/fcys']" routerLinkActive="router-link-active">
                <div class="card" style="height: 120px;">
                    <div class="card-body-5">
                        <h5 class="card-title text-white font-weight-bold mt-2">
                            <span class="float-right display-5 opacity-5"><mdb-icon class="gradient-1" fas icon="laugh"></mdb-icon></span> Familia, Comunidad y Sociedad </h5>
                    </div>
                </div>

            </a>
        </div>
    </div>
</div>

<!--Calendario-->
<section class="container my-5">
    <hr class="my-5">
    <!-- Grid row -->
    <div class="row">
        <!-- Calendario -->
        <div class="col-lg-7">
            <!-- Post title -->
            <h3 class="font-weight-bold text-unique mb-3"><strong>Calendario SEA</strong></h3>
            <br>
            <!--Docs-->
            <div class="container">
                <div class="single-news mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2025/Calendario_SEA_2025.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="orange-text pr-2"></mdb-icon>
                                    Descarga el calendario escolar de SEA 2025.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <div class="single-news mt-4 mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2025/calendario_certificados_2025.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="cyan-text pr-2"></mdb-icon>
                                    Consulta el calendario de expedición de Certificados SEA 2025.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <div class="single-news mt-4 mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2025/SEA_Calendario_Evaluaciones_2025.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="blue-text pr-2"></mdb-icon>
                                    Programación de actividades para la evaluación y expedición de Certificados SEA 2025.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <div class="single-news mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2024/Calendario_SEA_2024.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="purple-text pr-2"></mdb-icon>
                                    Descarga el calendario escolar de SEA 2024.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <div class="single-news mt-4 mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2024/calendario_certificados_2024.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="red-text pr-2"></mdb-icon>
                                    Consulta el calendario de expedición de Certificados SEA 2024.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <div class="single-news mt-4 mb-4">
                    <!-- Grid row -->
                    <div class="row">
                        <!-- Grid column -->
                        <!-- Grid column -->
                        <div class="col-md-12">
                            <!-- Excerpt -->
                            <p class="dark-grey-text">
                                <a href="assets/doc/2024/SEA_Calendario_Evaluaciones_2024.pdf" target="_blank">
                                    <mdb-icon fas icon="arrow-alt-circle-down" size="2x" class="teal-text pr-2"></mdb-icon>
                                    Programación de actividades para la evaluación y expedición de Certificados SEA 2024.

                                </a>
                            </p>
                        </div>
                        <!-- Grid column -->
                    </div>
                    <!-- Grid row -->
                </div>
                <br>
            </div>
        </div>
        <!-- Grid column -->
        <div class="col-lg-5">
            <!-- Featured image -->
            <div class="mb-lg-0 mb-4">
                <img class="img-fluid" src="assets/img/calendar.png" alt="Sample image">
                <a>
                    <div class="mask rgba-white-slight waves-effect waves-light"></div>
                </a>
            </div>
        </div>
        <!-- Grid column -->
    </div>
    <!-- Grid row -->
    <hr class="mb-5">
</section>
<!--Calendario-->
<!--Contact-->
<div class="container">
    <h3 class="text-center text-default mt-2 font-weight-bold"> Tus comentarios son muy importantes para nosotros</h3>
    <h5 _ngcontent-otn-c60="" class="dark-grey-text text-center">Escríbenos al correo: <span class="font-weight-bold">sea.dgme@nube.sep.gob.mx</span></h5>
    <br>
</div>
<!--Contact-->

<app-footer></app-footer>